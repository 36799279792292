<template lang="pug">
  .price-table
    table
      thead
        tr
          th.header-item.sticky-col.status
            span {{ $t("plan_management.table.columns.status") }}
          th.header-item.sticky-col.ota-name
            span {{ $t("plan_management.table.columns.ota_name") }}
          th.header-item.sticky-col.plan-name
            span {{ $t("plan_management.table.columns.plan_name") }}
          th.header-item.sticky-col.insurance
            span {{ $t("plan_management.table.columns.insurance") }}
          th.header-item.sticky-col.sale-start-date
            span {{ $t("plan_management.table.columns.sale_start_date") }}
          th.header-item.sticky-col.sale-stop-date
            span {{ $t("plan_management.table.columns.sale_stop_date") }}
          th.header-item.sticky-col.posting-start-date
            span {{ $t("plan_management.table.columns.posting_start_date") }}
          th.header-item.sticky-col.posting-end-date
            span {{ $t("plan_management.table.columns.posting_end_date") }}
          th.header-item.sticky-col.car-class
            span {{ $t("plan_management.table.columns.car_class") }}
          th.header-item.sticky-col.base-rate-period-group
            span {{ $t("plan_management.table.columns.base_rate_period_group") }}
          th.header-item.sticky-col.prices
            span {{ $t("plan_management.table.columns.price") }}
      tbody
        template(v-for="plan in plans")
          PlanRow(:plan="plan")
</template>

<script>
  export default {
    components: {
      PlanRow: () => import("./PlanRow")
    },

    props: {
      plansLoading: {
        type: Boolean,
        default: true
      },
      plans: {
        type: Array,
        default: () => new Array()
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"
  @import "@/assets/styles/tables/custom.sass"
  @import "@/assets/styles/matchings.sass"

  .price-table
    +listing-container(10px)
    position: relative
    overflow: auto

    thead
      th
        +custom-table-th

        &.sticky-col
          +sticky-col
          top: 0
          z-index: 10

        &.header-item
          +cell-width(286px)
          align-items: center
          span
            width: 260px

          &.status
            left: 0
            z-index: 11
          &.ota-name
            left: 72px
            z-index: 11
          &.plan-name
            left: 144px
            z-index: 11

          &.status,
          &.ota-name
            +cell-width(72px)

          &.plan-name
            +cell-width(200px)

          &.insurance
            +cell-width(100px)

          &.sale-start-date,
          &.sale-stop-date,
          &.posting-start-date,
          &.posting-end-date
            +cell-width(140px)

          &.car-class
            +cell-width(60px)
          &.base-rate-period-group
            +cell-width(100px)
</style>
